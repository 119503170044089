























import { computed, defineComponent, ref, watch } from "@vue/composition-api";
import axios from "@/plugins/axios";
import { selectingPharmacy } from "@/store/selectingPharmacy";
import { ChatRoomForm } from "@/models/lib/vue-advanced-chat/chatRoomForm";
import { CInput } from "@coreui/vue";

export default defineComponent({
  name: "NewInquiryModal",
  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const titleRef = ref<InstanceType<typeof CInput>>();

    const title = ref<string>("");
    const content = ref<string>("");
    const file = ref<File>(null);
    const isLoading = ref<boolean>(false);
    const errorMessages = ref<Array<string>>([]);

    const pharmacyId = typeof selectingPharmacy.state === "object" ? selectingPharmacy.state?.id : 0;

    const propsOpenModal = computed({
      get: () => props.openModal,
      set: (value: boolean) => emit("update:openModal", value),
    });
    const shouldDisableSubmitButton = computed(() => !(title.value && content.value) || isLoading.value);

    // 入力しているメッセージが変更されたら、エラーメッセージはクリアする
    watch(content, () => (errorMessages.value = []));
    watch(title, () => (errorMessages.value = []));
    watch(file, () => (errorMessages.value = []));
    watch(propsOpenModal, (value: boolean) => {
      if (value) titleRef.value?.$el.getElementsByTagName("input")[0].focus();
    });

    function hideModal() {
      propsOpenModal.value = false;
    }

    function onImageSelected(fileList: FileList) {
      if (fileList.length === 0) file.value = null;
      else file.value = fileList.item(0);
    }

    function splitExtension(filename: string) {
      return filename.split(/\.(?=[^.]+$)/);
    }

    async function sendMessage() {
      errorMessages.value = [];
      try {
        if (!content) return;
        isLoading.value = true;

        const url1 = `/api/v2/core/pharmacy/pharmacies/${pharmacyId}/inquiry_rooms`;
        const data: ChatRoomForm = {
          name: title.value,
          message: { content: content.value },
        };

        if (file.value) {
          const url2 = `/api/v2/core/pharmacy/pharmacies/${pharmacyId}/inquiry_messages/file_presigned_url`;
          const res = await axios.get(url2);
          const { presigned_url, object_name } = res.data;
          const fileData = await file.value?.arrayBuffer();
          await axios.put(presigned_url, fileData, { headers: { "content-type": file.value.type } });

          data.message.file = {
            file_name_s3: object_name,
            name: splitExtension(file.value.name)[0],
            size: file.value.size,
            file_type: file.value.type,
            extension: splitExtension(file.value.name)[1],
          };
        }

        await axios.post(url1, data);
        title.value = "";
        content.value = "";
        file.value = null;
        hideModal();
      } catch (e) {
        console.error(e);
        errorMessages.value = ["メッセージ送信でエラーが発生しました。"];
      } finally {
        isLoading.value = false;
      }
    }

    return {
      titleRef,
      title,
      content,
      isLoading,
      errorMessages,

      propsOpenModal,
      shouldDisableSubmitButton,

      hideModal,
      onImageSelected,
      sendMessage,
    };
  },
});
