import Vue from "vue";
import Vuex from "vuex";

import { CoreStore } from "./core";
import { selectingPharmacy } from "@/store/selectingPharmacy";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    core: CoreStore,
    selectingPharmacy: selectingPharmacy,
  },
});
