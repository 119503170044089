<template>
  <CCard v-if="pharmacy">
    <CCardHeader>{{ pharmacyName }}</CCardHeader>
    <CCardBody>
      <CForm>
        <CInput v-if="pharmacy.company" v-model="pharmacy.company.name" label="薬局会社名" horizontal disabled />
        <CInput v-model="pharmacy.name" label="薬局名" horizontal />
        <CInput v-model="pharmacy.micode" label="医療機関コード" :description="askSupport" horizontal disabled />
        <CInput
          v-if="pharmacy.active_license"
          v-model="pharmacy.active_license.number"
          label="開設許可番号"
          :description="askSupport"
          horizontal
          disabled
        >
          <template #append>
            <CButton v-if="pharmacy.active_license" color="primary" :href="pharmacy.active_license.signed_url" target="_blank">
              開設許可証を確認する
            </CButton>
          </template>
        </CInput>
        <CInput
          v-if="pharmacy.active_license"
          v-model="pharmacy.active_license.expires_on"
          label="開設許可の有効期限"
          :description="askSupport"
          type="date"
          max="9999-12-31"
          horizontal
          disabled=""
        />
        <CInput v-model="pharmacyInvoiceNumber" label="登録番号" horizontal />
        <CInput v-model="pharmacyPostalCode" label="郵便番号" description="郵便番号 e.g. 101-0051" horizontal>
          <template #append>
            <CButton color="primary" @click="setAddressesByPostalCode">住所を自動設定</CButton>
          </template>
        </CInput>
        <CInput v-model="pharmacy.address1" label="住所1" description="都道府県 e.g.東京都" horizontal />
        <CInput v-model="pharmacy.address2" label="住所2" description="市区郡 e.g. 千代田区" horizontal />
        <CInput v-model="pharmacy.address3" label="住所3" description="町村・字 e.g. 神田神保町" horizontal />
        <CInput v-model="pharmacy.address4" label="住所4" description="それ以降 e.g. 1-2-3 水野ビル3階" horizontal />
        <CInput v-model="pharmacy.phone" label="電話" horizontal />
        <CInput v-model="pharmacy.fax" label="FAX" horizontal />
        <CInput v-model="pharmacy.email" label="メールアドレス" type="email" description="薬局の代表メールアドレス" horizontal />
        <CInput v-model="pharmacy.web_url" label="WEB URL" horizontal />
        <CRow>
          <CCol class="col-form-label col-sm-3">契約サービス</CCol>
          <CCol class="col-sm-5 px-0">
            <table class="table table-borderless">
              <tbody>
                <tr v-for="(service, index) in listingServices" :key="index">
                  <td class="pt-1">{{ service.service_name }}</td>
                  <td class="pt-1">
                    <div v-if="service.service_status">契約中</div>
                    <div v-else>未契約</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </CCol>
        </CRow>
        <CInput v-model="pharmacy.subdivision_transferor_name" label="譲渡人（小分け）" horizontal />
        <CInput v-model="pharmacy.subdivision_transferee_name" label="譲受人（小分け）" horizontal />
      </CForm>
      <CAlert :show.sync="successAlertCountDown" color="success" close-button>{{ saveResult }}</CAlert>
      <CAlert :show="shouldShowDangerAlert" color="danger">
        <ul v-for="error in errors" :key="error" class="mb-0">
          <li>{{ error }}</li>
        </ul>
      </CAlert>
    </CCardBody>
    <CCardFooter>
      <div class="float-right">
        <CButton color="primary" @click="savePharmacy()">更新</CButton>
      </div>
    </CCardFooter>
  </CCard>
</template>

<script>
import Vue from "vue";

export default {
  name: "Pharmacy",
  data() {
    return {
      pharmacy: null,
      pharmacyName: null,
      pharmacyPostalCode: "",
      pharmacyServices: { medshare: true, medorder: true },
      pharmacyInvoiceNumber: "",
      saveResult: null,
      successAlertCountDown: 0,
      errors: [],
      askSupport: "変更の際は、サポートまでお問い合わせください。",
      medorder_url: process.env.VUE_APP_V2_APP_MEDORDER_HOST,
    };
  },
  computed: {
    pharmacyId() {
      return this.$store.getters["selectingPharmacy/id"];
    },
    shouldShowDangerAlert() {
      return this.errors.length > 0;
    },
    listingServices() {
      if (!this.pharmacy.id) return;

      const srv_list = [];
      srv_list.push({
        service_name: "メドシェア",
        service_status: this.pharmacy.services.includes("medshare"),
      });
      srv_list.push({
        service_name: "メドオーダー",
        service_status: this.pharmacy.services.includes("medorder"),
      });
      return srv_list;
    },
  },
  watch: {
    pharmacyPostalCode() {
      this.pharmacy.postal_code = this.pharmacyPostalCode;
      if (!this.pharmacy.address1 && !this.pharmacy.address2 && !this.pharmacy.address3) {
        this.setAddressesByPostalCode();
      }
    },
  },
  async created() {
    await this.$store.dispatch("core/pharmacies/fetch");
    await this.setPharmacyData();
  },
  methods: {
    async savePharmacy() {
      try {
        this.errors = [];
        this.pharmacy.pharmacy_invoice_attributes = { invoice_number: this.pharmacyInvoiceNumber };
        await this.$store.dispatch("core/pharmacies/update", this.pharmacy);
        await this.setPharmacyData();
        this.saveResult = "更新成功しました。";
        this.successAlertCountDown = 3;
      } catch (error) {
        this.errors = error?.response?.data?.errors || ["何らかのエラーが発生しました。"];
      }
    },
    async setPharmacyData() {
      this.pharmacy = Vue.util.extend({}, this.$store.state.core.pharmacies.selecting); // 値渡し
      this.pharmacyName = this.pharmacy.name;
      this.pharmacyPostalCode = this.pharmacy.postal_code;
      this.pharmacyInvoiceNumber = this.pharmacy.pharmacy_invoice?.invoice_number;
    },
    async setAddressesByPostalCode() {
      if (!/[0-9]{7}/.test(this.pharmacyPostalCode.replace("-", ""))) return;

      const req_params = { postal_code: this.pharmacyPostalCode };
      const response = (await this.$axios.get("/api/v2/core/master/postal_codes/search", { params: req_params })).data;
      if (response && response.results && response.results[0]) {
        this.pharmacy.address1 = response.results[0]["address1"];
        this.pharmacy.address2 = response.results[0]["address2"];
        this.pharmacy.address3 = response.results[0]["address3"];
      }
    },
  },
};
</script>
