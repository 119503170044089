import axios from "@/plugins/axios";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function doDownload(window: Window & any, url: string, filename: string) {
  const response = await axios.get(url, { responseType: "blob" });
  const fileURL = window.URL.createObjectURL(new Blob([response.data]));
  const fileLink = document.createElement("a");
  fileLink.href = fileURL;
  fileLink.setAttribute("download", filename);
  document.body.appendChild(fileLink);
  fileLink.click();
}
