








import "@coreui/coreui";

export default {};
