import { GetterTree } from "vuex";
import { RootState } from "@/store/types";
import { SelectingPharmacyState } from "./types";

const selectingPharmacyGetters: GetterTree<SelectingPharmacyState, RootState> = {
  id: (state: SelectingPharmacyState) => {
    return state.id;
  },
  isSelecting: (state: SelectingPharmacyState) => {
    return !!state.id;
  },
};

export default selectingPharmacyGetters;
