<template>
  <div>
    <CAlert v-if="!result" color="light">auth0の情報から、DB上のユーザ情報を復元しています。</CAlert>
    <div v-else>
      <CAlert v-if="isSamePharmacyIds" color="success">
        auth0の情報から、DB上のユーザ情報を復元しました。
      </CAlert>
      <CAlert v-else color="warning">
        pharmacy_usersテーブル情報が復元されませんでした。<br />
        <br />
        該当するIDの薬局が登録されていない可能性があります。<br />
        その場合、薬局を登録して再度このページで復元して下さい。
      </CAlert>

      <CCard class="mb-1">
        <CCardHeader>結果</CCardHeader>
        <CCardBody>
          <pre>{{ JSON.stringify(result, null, 2) }}</pre>
        </CCardBody>
      </CCard>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserRestore",
  data() {
    return {
      result: null,
    };
  },
  computed: {
    isSamePharmacyIds() {
      if (this.result) {
        return this.result["auth0_pharmacy_ids"].length === this.result["db_pharmacy_ids"].length;
      }
      return false;
    },
  },
  async created() {
    const response = await this.$axios.post("/api/v2/core/common/users/restore", {});
    this.result = response.data;
  },
};
</script>
