import { ActionTree } from "vuex";
import { SelectingPharmacyState } from "./types";
import { RootState } from "@/store/types";
import Cookies from "js-cookie";

const selectingPharmacyActions: ActionTree<SelectingPharmacyState, RootState> = {
  set: async ({ commit }, id: number) => {
    Cookies.set("selecting_pharmacy_id", String(id), { expires: new Date("9999-12-31") });
    await commit("set", id);
  },
  remove: async ({ commit }) => {
    Cookies.remove("selecting_pharmacy_id");
    await commit("set", undefined);
  },
};

export default selectingPharmacyActions;
