import axios from "src/plugins/axios";
import { Module } from "vuex";
import { Pharmacy } from "src/lib/models/pharmacy";
import PromiseObserver from "src/lib/utils/promiseObserver";

const fetchObserver = new PromiseObserver();

export type RootState = {};

export type CorePharmaciesState = {
  selecting: Pharmacy | null;
  list: Pharmacy[];
  isFetching: boolean;
  isFetched: boolean;
};

function initState(): CorePharmaciesState {
  return {
    selecting: null,
    list: [],
    isFetching: false,
    isFetched: false,
  };
}

export const CorePharmaciesStore: Module<CorePharmaciesState, RootState> = {
  namespaced: true,
  state: initState(),
  getters: {},
  mutations: {
    reset(state) {
      Object.assign(state, initState());
    },
    fetchStart(state) {
      state.isFetching = true;
    },
    fetchEnd(state, payload: { selecting: Pharmacy; list: Pharmacy[] }) {
      if (payload) {
        state.selecting = payload.selecting;
        state.list = payload.list;
        state.isFetched = true;
      }
      state.isFetching = false;
      fetchObserver.notify();
    },
  },
  actions: {
    async fetch(context) {
      try {
        if (context.state.isFetching) {
          await fetchObserver.createWaitPromise();
          return;
        }

        context.commit("fetchStart");
        const response = await axios.get("/api/v2/core/pharmacy/pharmacies/me"); // TODO: ページング

        const payload = {
          selecting: null,
          list: response.data.map((p: Pharmacy) => {
            p.address = `${p.address1}${p.address2}${p.address3}${p.address4 || ""}`;
            return p;
          }),
        };
        const pharmacyId = parseInt(context.rootGetters["selectingPharmacy/id"]);
        if (pharmacyId) {
          payload.selecting = response.data.find((pharmacy: Pharmacy) => pharmacy.id === pharmacyId);
        }

        context.commit("fetchEnd", payload);
      } catch (e) {
        context.commit("fetchEnd");
      }
    },
    async update(context, payload) {
      const pharmacyId = payload.id;
      if (payload && pharmacyId) {
        await axios.put(`/api/v2/core/pharmacy/pharmacies/${pharmacyId}`, payload);
        await context.dispatch("fetch");
      }
    },
    reset(context): void {
      context.commit("reset");
    },
  },
};
