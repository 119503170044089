<template>
  <CContainer class="d-flex align-items-center min-vh-100">
    <CRow class="w-100 justify-content-center">
      <CCol md="8">
        <div class="w-100">
          <div class="clearfix">
            <CAlert v-if="isLoading" color="light">読み込み中</CAlert>
            <CCard v-else class="mb-1">
              <CCardHeader>薬局を選択してください</CCardHeader>
              <CCardBody>
                <SelectPharmacyTable @selectPharmacy="selectPharmacy" />
              </CCardBody>
            </CCard>
          </div>
        </div>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import SelectPharmacyTable from "src/components/SelectPharmacyTable";

export default {
  name: "SelectPharmacy",
  components: { SelectPharmacyTable },
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    pharmacies() {
      return this.$store.state.core.pharmacies.list;
    },
    pharmacyIds() {
      return this.pharmacies.map((pharmacy) => pharmacy.id);
    },
  },
  async created() {
    // 所属薬局の一覧を取得する。
    await this.$store.dispatch("core/pharmacies/fetch");

    // すでに選択薬局があれば、ページ移動。
    if (this.$store.getters["selectingPharmacy/id"]) {
      if (this.pharmacyIds.includes(parseInt(this.$store.getters["selectingPharmacy/id"]))) {
        await this.routerNext();
      }
    }

    // 薬局が1つであれば、選択薬局として記録してページ移動。
    if (this.pharmacies.length === 1) {
      await this.selectPharmacy(this.pharmacies[0]);
    }

    // 薬局が複数の場合は、選択画面を表示させる。
    this.isLoading = false;
  },
  methods: {
    async routerNext() {
      // とりあえずは、トップページへ移動。
      // TODO: その前にいたページを記録して移動させたい。
      await this.$router.push({ name: "薬局情報" });
    },
    async selectPharmacy(pharmacy) {
      await this.$store.dispatch("selectingPharmacy/set", pharmacy.id);
      await this.routerNext();
    },
  },
};
</script>
