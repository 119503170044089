import { MutationTree } from "vuex";
import { SelectingPharmacyState } from "./types";

const selectingPharmacyMutations: MutationTree<SelectingPharmacyState> = {
  set: (state, id: number) => {
    state.id = id;
  },
};

export default selectingPharmacyMutations;
