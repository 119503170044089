








import { defineComponent, ref } from "@vue/composition-api";
import NewInquiryModal from "@/views/inquiries/NewInquiryModal.vue";

export default defineComponent({
  name: "SubHeaderInquires",
  components: { NewInquiryModal },
  setup() {
    const isOpenModal = ref<boolean>(false);

    function openModal() {
      isOpenModal.value = true;
    }

    return {
      isOpenModal,
      openModal,
    };
  },
});
