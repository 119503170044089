import { Module } from "vuex";

import { CorePharmaciesStore } from "./pharmaciesStore";

export type CoreStoreState = {};
export type RootState = {};

export const CoreStore: Module<CoreStoreState, RootState> = {
  namespaced: true,
  state: {},
  modules: {
    pharmacies: CorePharmaciesStore,
  },
};
