import { DateTime } from "luxon";
import { ChatFile, ChatMessage } from "vue/types/vue-advanced-chat";
import { ChatFileClass } from "@/models/lib/vue-advanced-chat/chatFileClass";

export class ChatMessageClass implements ChatMessage {
  id?: number;
  _id?: string | number;
  content?: string;
  sender_id?: number;
  reply_message_id?: number | null;
  reply_message?: ChatMessage | null;
  replyMessage?: ChatMessage | null;
  pharmacy_name?: string;
  username?: string;
  date?: string | number;
  timestamp?: string;
  system?: boolean;
  saved?: boolean;
  edited?: boolean;
  distributed?: boolean;
  seen?: boolean;
  disable_actions?: boolean;
  disable_reactions?: boolean;
  file?: ChatFile;
  files?: ChatFile[];
  created_at?: string;
  createdAt?: DateTime;
  updated_at?: string;
  updatedAt?: DateTime;

  static create(message: ChatMessageClass): ChatMessageClass {
    message._id = message.id;

    message.createdAt = message.created_at ? DateTime.fromISO(message.created_at) : undefined;
    message.date = message.createdAt ? message.createdAt.toJSDate().toLocaleDateString() : "-";
    message.timestamp = message.createdAt ? message.createdAt.toJSDate().toLocaleString() : "-";

    message.saved = true;
    message.seen = true;
    message.disable_actions = false;
    message.disable_reactions = true;

    if (message.reply_message) {
      message.replyMessage = ChatMessageClass.create(message.reply_message);
    }
    if (message.files && message.files.length > 0) {
      message.file = ChatFileClass.create(message.files[0]);
    }
    return message;
  }
}
