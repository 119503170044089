import { Module } from "vuex";
import { RootState } from "@/store/types";
import selectingPharmacyActions from "./selectingPharmacyActions";
import selectingPharmacyGetters from "./selectingPharmacyGetters";
import selectingPharmacyMutations from "./selectingPharmacyMutations";
import { SelectingPharmacyState } from "@/store/selectingPharmacy/types";
import Cookies from "js-cookie";

const state: SelectingPharmacyState = {
  id: Number(Cookies.get("selecting_pharmacy_id")) || undefined,
};

export const selectingPharmacy: Module<SelectingPharmacyState, RootState> = {
  namespaced: true,
  state,
  getters: selectingPharmacyGetters,
  actions: selectingPharmacyActions,
  mutations: selectingPharmacyMutations,
};
