


















import { computed, defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "DeleteConfirmationModal",
  props: {
    title: { type: String, default: "削除" },
    cancelButtonLabel: { type: String, default: "キャンセル" },
    deleteButtonLabel: { type: String, default: "削除する" },
    errorMessages: { type: Array, default: () => [] },
    message: { type: String, default: "削除しますが、本当によろしいですか？" },
    subMessage: { type: String, default: null },
    openModal: { type: Boolean, default: false },
    submitAction: { type: Function, default: null },
  },
  setup(props, { emit }) {
    const propsOpenModal = computed({
      get: () => props.openModal,
      set: (value: boolean) => emit("update:openModal", value),
    });

    function hideModal() {
      propsOpenModal.value = false;
    }

    async function submitHandler() {
      emit("submit-action");
      hideModal();
    }

    return {
      propsOpenModal,
      hideModal,
      submitHandler,
    };
  },
});
