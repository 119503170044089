import { ChatMessage, ChatRoom, ChatUser } from "vue/types/vue-advanced-chat";
import { DateTime } from "luxon";

export class ChatRoomClass implements ChatRoom {
  id?: number;
  roomId?: number;
  name?: string;
  roomName?: string;
  unread_count?: number;
  unreadCount?: number | string;
  closed?: boolean;
  completed?: boolean;
  last_message?: ChatMessage;
  lastMessage?: ChatMessage;
  users?: ChatUser[];

  static create(room: ChatRoomClass): ChatRoomClass {
    room.roomId = room.id;
    room.roomName = room.name;
    room.unreadCount = room.unread_count;
    room.lastMessage = room.last_message;
    if (room.lastMessage && room.lastMessage.created_at) {
      const createdAt = DateTime.fromISO(room.lastMessage.created_at);
      room.lastMessage.timestamp = createdAt.toLocaleString() || "-";
      room.lastMessage.date = createdAt.toMillis() || 0;
    }
    room.users = [
      // メッセージにユーザー名を強制的に表示させるために、ダミーユーザーが3人所属しているように設定。
      { _id: -1, username: "" },
      { _id: -2, username: "" },
      { _id: -3, username: "" },
    ];
    return room;
  }
}
