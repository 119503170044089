export default class PromiseObserver {
  private resolves: (() => void)[] = [];

  public createWaitPromise() {
    return new Promise((resolve) => {
      this.resolves.push(resolve);
    });
  }

  public notify() {
    for (const resolve of this.resolves) {
      resolve();
    }
    this.resolves = [];
  }
}
