<template>
  <CContainer class="d-flex align-items-center min-vh-100">
    <CRow class="w-100 justify-content-center">
      <CCol md="8">
        <div class="w-100">
          <div class="clearfix">
            <CAlert class="text-center" color="light">削除中</CAlert>
          </div>
        </div>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
// SelectPharmacyTable.vue で選択した薬局を、消去する画面。
// 開発用途でのみ使用する想定。

export default {
  name: "ResetPharmacy",
  async created() {
    await this.$store.dispatch("selectingPharmacy/remove");
    await this.$router.push("/select-pharmacy");
  },
};
</script>
