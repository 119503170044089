import {
  cibCcAmex,
  cibCcMastercard,
  cibCcVisa,
  cibGooglePay,
  cibStripe,
  cifBr,
  cifEs,
  cifFr,
  cifIn,
  cifPl,
  cifUs,
  cilBell,
  cilBuilding,
  cilChatBubble,
  cilCommentSquare,
  cilDollar,
  cilEnvelopeOpen,
  cilFile,
  cilList,
  cilLockLocked,
  cilSettings,
  cilShieldAlt,
  cilTask,
  cilUser,
  cilYen,
} from "@coreui/icons";

export const iconsSet = Object.assign(
  {},
  {
    cilBell,
    cilBuilding,
    cilChatBubble,
    cilCommentSquare,
    cilDollar,
    cilEnvelopeOpen,
    cilFile,
    cilList,
    cilLockLocked,
    cilSettings,
    cilShieldAlt,
    cilTask,
    cilUser,
    cilYen,
  },
  {
    cifUs,
    cifBr,
    cifIn,
    cifFr,
    cifEs,
    cifPl,
  },
  {
    cibCcAmex,
    cibCcMastercard,
    cibCcVisa,
    cibGooglePay,
    cibStripe,
  }
);
