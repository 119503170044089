











import { computed, defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "ImageModal",
  props: {
    file: { type: Object, default: null },
    openModal: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const propsOpenModal = computed({
      get: () => props.openModal,
      set: (value: boolean) => emit("update:openModal", value),
    });
    const title = computed(() => (props.file ? `${props.file?.name}${props.file?.type}` : ""));
    const url = computed(() => (props.file ? props.file.url : ""));

    function hideModal() {
      propsOpenModal.value = false;
    }

    return {
      title,
      url,
      propsOpenModal,
      hideModal,
    };
  },
});
